import * as React from "react"
import { Link } from "gatsby"

const Plans = () => {
	return (
		<div className="plans-subnav">
			<div className="constrained">
				<nav aria-label="Plans Menu">
					<ul>
						<li><Link to="/plans/uvm-health-advantage/" partiallyActive={true} activeClassName="current">Plans</Link></li>
						<li><Link to="/plans/benefits-savings-support/" partiallyActive={true} activeClassName="current">Benefits, Savings and Support</Link></li>
					</ul>
				</nav>
			</div>
		</div>
	)
}

export default Plans;
