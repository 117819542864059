import * as React from "react"
import { useEffect } from 'react';
import Layout from "../../templates/layout-standard"
import Seo from "../../components/seo"
import PlansNav from '../../components/nav/PlansNav'
import SideBar from '../../components/sidebar';
import SpeakWithAdvisor from '../../components/ui/SpeakWithAdvisor';

function toggleAccordion(event) {
	let accordionContainer = this.nextElementSibling;
	this.classList.toggle("open");
	if (this.classList.contains('open')) {
		accordionContainer.setAttribute("aria-hidden", "false");
	} else {
		accordionContainer.setAttribute("aria-hidden", "true");
	}

	if (!accordionContainer.classList.contains('open')) {
		accordionContainer.classList.add('open');
		accordionContainer.style.height = 'auto';
	
		let height = accordionContainer.clientHeight + 'px';
	
		accordionContainer.style.height = '0px';
	
		setTimeout(function () {
			accordionContainer.style.height = height;
		}, 0);
	} else {
		accordionContainer.style.height = '0px';		
		accordionContainer.addEventListener('transitionend', function () {
			accordionContainer.classList.remove('open');
		}, {
			once: true
		});
	}
}

const BenefitsSavingsSupport = ({ location }) => {

	useEffect(() => {
		document.querySelectorAll(".accordiontrigger").forEach(trigger => {
			trigger.removeEventListener('click', toggleAccordion);
			trigger.addEventListener('click', toggleAccordion);
		});
	});

	return(
		<Layout location={location} heading1="Medicare Advantage Plans" heading2="Great benefits. Extra value.">

			<Seo title="Benefits, Savings and Support | Plans" meta={[{description: 'Learn about the dental, vision, and hearing benefits included with UVM Health Advantage plans.'}]} bodyclass="plans health-and-wellness subpage" />
			<PlansNav />
			
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">
						<main>
							<h3>Benefits, Savings and Support</h3>
							<p>Get the care and support you need to take on your health goals and challenges to live your best life.</p>

							<h4>Dental, Vision and Hearing &ndash; Key to Good Health</h4>

							<div className="accordion">
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-1" className="accordiontrigger" aria-controls="accordion-content-1">Comprehensive Dental</button>
									<div id="accordion-content-1" className="accordion-content" aria-labelledby="accordion-trigger-1" aria-hidden="true">
										<div className="contentwrap">
											<p>Dental care is key to your overall health.</p>
											<p>Preventive services, including oral exams, routine cleaning and x-rays, are covered in full!</p>
											<p>Our plans also include up to $2,000 each year for other covered dental services including:</p>
											<ul>
												<li>Routine cleanings</li>
												<li>Root canals</li>
												<li>Oral exams</li>
												<li>Oral surgery</li>
												<li>X-rays Crowns and bridges</li>
												<li>Fillings</li>
												<li>Partial dentures</li>
												<li>Simple tooth extractions</li>
												<li>And more</li>
											</ul>
											<p>Based on the service received, you may be required to pay a portion of the cost. Your cost (after deductible is met) varies based on the type of service received. Talk with a Plan Guide to learn more.</p>

											<b>See Any Dentist</b>
											<p>With UVM Health Advantage, you can see any dentist you choose! Your plan includes access to the nationwide DenteMax Medicare network, where your costs will be lower. You can choose an out-of-network dentist, but you may have to pay more for services.</p>

											<p><a href="https://find.dentemaxportal.com/Members/FindADentist.aspx" className="external" target="_blank" rel="noreferrer noopener">Conﬁrm if your dentist is part of the DenteMax network.</a></p>
											
										</div>
									</div>
								</div>
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-2" className="accordiontrigger" aria-controls="accordion-content-2">Vision and Eyewear</button>
									<div id="accordion-content-2" className="accordion-content" aria-labelledby="accordion-trigger-2" aria-hidden="true">
										<div className="contentwrap">
											<p>UVM Health Advantage provides valuable vision benefits &ndash; including coverage for routine eye exams with co-pays as low as $0. You also get up to $225 to use annually for any kind of eyewear, from glasses to prescription sunglasses to contact lenses. And with additional vision benefits powered by EyeMed, you can choose from a variety of retailers, with in-store and online options, plus discounts on popular designer brands.</p>
										</div>
									</div>
								</div>
			
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-3" className="accordiontrigger" aria-controls="accordion-content-3">Hearing Exams and Hearing Aids</button>
									<div id="accordion-content-3" className="accordion-content" aria-labelledby="accordion-trigger-3" aria-hidden="true">
										<div className="contentwrap">
											<p>With UVM Health Advantage, hearing exams from an in-network provider are covered in full. Good hearing helps you stay connected and involved in life&rsquo;s special moments. That&rsquo;s why UVM Health Advantage plans offer a flexible hearing aid benefit through TruHearing to make hearing aids more affordable, and allowing you to choose the device that best fits your needs. Your flexible hearing benefit covers up to two TruHearing Advanced or Premium hearing aids per year at low copayments, or you can apply an allowance to a broader catalogue of hearing aids.</p>
										</div>
									</div>
								</div>
								
								<div className="accordion-item videos">
									<button type="button" id="accordion-trigger-4" className="accordiontrigger" aria-controls="accordion-content-4">Personalized Home Health Care Kits</button>
									<div id="accordion-content-4" className="accordion-content" aria-labelledby="accordion-trigger-4" aria-hidden="true">
										<div className="contentwrap">
											<p>UVM Health Advantage plans offer support for our members to stay well and manage ongoing conditions. Personalized home-health kits are provided to members living with diabetes and congestive heart failure. Kits are also provided to members recovering from a joint replacement surgery. UVM Health Advantage Care Guides can help you understand your conditions and access support programs specific to your needs. Watch the videos below to learn more about our home-health care kits.</p>
											<div className="videoflexwrapper">
												<div className="videowrapper">
													<div class="videocontainer ratio9_16">	
														<iframe src="https://player.vimeo.com/video/799583242?h=4d3f68b0af" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="UVMHA_Care_Kit_CFH_Final2"></iframe>
													</div>
												</div>
												<div className="videowrapper">
													<div class="videocontainer ratio9_16">	
														<iframe src="https://player.vimeo.com/video/799584273?h=f6d3720c06" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="UVMHA_Care_Kit_Joint_Replacement_Final2"></iframe>
													</div>
												</div>
												<div className="videowrapper">
													<div class="videocontainer ratio9_16">	
														<iframe src="https://player.vimeo.com/video/799583793?h=b91d121b3c" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="UVMHA_Care_Kit_Diabetes_Final1"></iframe>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-5" className="accordiontrigger" aria-controls="accordion-content-5">Chronic Pain Management</button>
									<div id="accordion-content-5" className="accordion-content" aria-labelledby="accordion-trigger-5" aria-hidden="true">
										<div className="contentwrap">
											<p><strong>NEW</strong> Chiropractic and / or Acupuncture</p>
											<ul>
												<li>Additional visits can be used for Chiropractic, Acupuncture, or a combination of those services</li>
												<li>Up to 20 additional visits, depending on plan</li>
											</ul>
										</div>
									</div>
								</div>
								
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-6" className="accordiontrigger" aria-controls="accordion-content-6">Mental Health</button>
									<div id="accordion-content-6" className="accordion-content" aria-labelledby="accordion-trigger-6" aria-hidden="true">
										<div className="contentwrap">
											<p>Mental Health means Overall Health.</p>
											<p>Self-care means listening to yourself. Whether it&apos;s your physical health or your mental health, at UVM Health Advantage we cover the whole you.</p>
											<ul>
												<li>Select and Secure Plans: $20 co-pay IN individual service/$10 co-pay IN group service</li>
												<li>Preferred Plan: $0 co-pay IN individual/group</li>
												<li>Telehealth Mental Health visits through Gia<sup>&reg;</sup> continue to have no costs</li>
											</ul>
										</div>
									</div>
								</div>
								
							</div>

							<SpeakWithAdvisor />
						</main>
						<SideBar location={location} />

					</div>
				</div>
			</div>
				
			
		</Layout>
	)
}

export default BenefitsSavingsSupport
